import * as React from "react";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import styled from "styled-components";
import { Colors } from "styles/Colors";
import Store from "common/store";
// eslint-disable-next-line no-unused-vars
import { EventFormatData } from "common/helpers/eventFormatHelper";
import { EventStageTypes } from "common/constants/EventStageTypes";

const propKeys = {
	eventData: StoreKeys.EVENT.EVENTDATA,
	format: StoreKeys.EVENT.FORMAT,
	stageIndex: StoreKeys.EVENT.STAGE_INDEX,
	substageIndex: StoreKeys.EVENT.SUBSTAGE_INDEX,
};

/**
 * @typedef StageSelectorCProps
 * @type {object}
 * @property {any} eventData
 * @property {EventFormatData} format
 * @property {number} stageIndex
 * @property {number} substageIndex
 */

const StageLabel = styled.label`
	margin: auto;
`;

const StageButton = styled.div`
	display: flex;
	width: 100px;
	margin: 4px;
	padding: 4px;
	border-radius: 12px;
	text-align: center;
	padding: 4px;
	word-spacing: 9999px;
	background: ${({ selected }) => (selected ? Colors.primary : Colors.panel)};
	color: ${({ selected, unlocked }) =>
		selected
			? Colors.panel
			: unlocked
			? Colors.primary
			: Colors.panelLighterColor};
	cursor: ${({ unlocked }) => (unlocked ? "pointer" : "default")};
	font-weight: 400;
	overflow-wrap: break-word;
	align-content: center;
	justify-content: center;

	${StageLabel} {
		cursor: ${({ unlocked }) => (unlocked ? "pointer" : "default")};
	}
`;

/**
 * @param {StageSelectorCProps} props
 */
function StageSelectorC({ eventData, format, stageIndex, substageIndex }) {
	const currentStage = format.stages[stageIndex];
	/** @type {EventStage} */
	const currentEventStage = eventData.eventStages[stageIndex];

	if (format.stages.length <= 1) {
		return <></>;
	}

	const changeStage = (i) => {
		if (i === stageIndex) {
			return;
		}

		if (i > eventData.stage) {
			return;
		}

		const updates = {};
		updates[StoreKeys.EVENT.STAGE_INDEX] = i;
		updates[StoreKeys.EVENT.SUBSTAGE_INDEX] = 0;

		Store.setMany(updates);
	};

	const changeSubstage = (i) => {
		if (i === substageIndex) {
			return;
		}

		if (i >= currentEventStage.eventSubstage.length) {
			return;
		}

		Store.set(StoreKeys.EVENT.SUBSTAGE_INDEX, i);
	};

	const isGroupStageBrackets =
		currentStage?.type === EventStageTypes.Group &&
		currentStage?.groupStageDetails?.hasGroupBrackets;

	return (
		<>
			<div
				style={{ display: "flex", justifyContent: "center", marginBottom: 8 }}
			>
				{format.stages.map((s, i) => {
					const selected = i === stageIndex;
					const unlocked = i <= eventData.stage;
					return (
						<StageButton
							selected={selected}
							unlocked={unlocked}
							onClick={() => changeStage(i)}
							key={i}
						>
							<StageLabel>{s.name}</StageLabel>
						</StageButton>
					);
				})}
			</div>

			{isGroupStageBrackets && currentEventStage?.eventSubstage?.length > 1 && (
				<div
					style={{ display: "flex", justifyContent: "center", marginBottom: 8 }}
				>
					{currentEventStage.eventSubstage.map((ss, i) => {
						const selected = i === substageIndex;
						return (
							<StageButton
								selected={selected}
								unlocked={true}
								onClick={() => changeSubstage(i)}
								key={i}
							>
								<StageLabel>{ss.title}</StageLabel>
							</StageButton>
						);
					})}
				</div>
			)}
		</>
	);
}

export default function StageSelector(props) {
	return connect(<StageSelectorC />, propKeys, props);
}
