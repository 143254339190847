import {
	Box,
	Button,
	Container,
	FormLabel,
	Input,
	TextField,
	makeStyles,
} from "@material-ui/core";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import * as React from "react";
// eslint-disable-next-line no-unused-vars
import { SurveyDto } from "common/types/Survey/SurveyDto";
import Store from "common/store";
import SurveyService from "services/SurveyService";
import { FormControl } from "@mui/material";
import { Colors } from "styles/Colors";
import { toMMDDYYYYWithHourMintues } from "common/helpers/dateHelper";
import { SurveyPromptDto } from "common/types/Survey/SurveyPromptDto";
import { toast } from "react-toastify";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import TileGroup from "common/components/TileGroup/TileGroup";
import Paper from "common/components/Paper/Paper";
// eslint-disable-next-line no-unused-vars
import { SurveyPromptAnswerDto } from "common/types/Survey/SurveyPromptAnswerDto";
import SurveyAnswerTile from "common/components/SurveyAnswerTile/SurveyAnswerTile";

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {SurveyDto} current
 */

const propKeys = {
	current: StoreKeys.ADMIN.SURVEYS.CURRENT,
};

const useStyles = makeStyles((theme) => {
	return {
		buttonRow: {
			marginTop: theme.spacing() * 2,
			marginBottom: 12,
			"& svg": {
				position: "relative",
				top: -2,
				left: -2,
			},
		},
		promptRow: {},
		promptHeader: {
			textAlign: "left",
			"& th": {
				textDecoration: "underline",
			},
		},
		selectedPrompt: {
			background: Colors.panelLighterColor,
		},
	};
});

/**
 * @param {ComponentProps} props
 */
function SurveyEditorC({ current }) {
	const classNames = useStyles();
	const [selectedIndex, setSelectedIndex] = React.useState(null);
	const [isPreviewing, setIsPreviewing] = React.useState(false);
	const selectedPrompt = current.prompts[selectedIndex];

	React.useEffect(() => {
		var isCtrl = false;
		const callback = (e) => {
			if (e.keyCode === 17) {
				e.preventDefault();
				isCtrl = true;
			}

			if (e.keyCode === 83 && isCtrl) {
				e.preventDefault();
				save();
			}
		};

		window.addEventListener("keydown", callback);
		return () => {
			window.removeEventListener("keydown", callback);
		};
	});

	const save = async (isClosing) => {
		await SurveyService.saveSurvey(current).then((surveyId) => {
			if (!isClosing) {
				current.surveyId = surveyId;
				Store.set(StoreKeys.ADMIN.SURVEYS.CURRENT, current);
			}
			toast("Survey Saved!", { type: "success" });
		});
	};

	const close = () => {
		Store.remove(StoreKeys.ADMIN.SURVEYS.CURRENT);
	};

	const saveAndClose = () => {
		save(true).then(() => {
			close();
		});
	};

	const updateState = () => {
		Store.set(StoreKeys.ADMIN.SURVEYS.CURRENT, current);
	};

	/**
	 *
	 * @param {React.DragEvent<HTMLTableRowElement>} e
	 * @param {number} id
	 */
	const onDrag = (e, index) => {
		e.dataTransfer.dropEffect = "move";
		e.dataTransfer.setData("id", index.toString());
	};

	/**
	 *
	 * @param {React.DragEvent<HTMLTableRowElement>} e
	 * @param {number} id
	 */
	const onDrop = (e, index) => {
		e.preventDefault();

		// Move old record to current index
		const oldIndex = Number(e.dataTransfer.getData("id"));

		// If moving forward
		const recordToMove = current.prompts.splice(oldIndex, 1)[0];
		const backend = current.prompts.splice(index);
		current.prompts.push(recordToMove);
		current.prompts = current.prompts.concat(backend);

		updateState();
	};

	const addPrompt = () => {
		current.prompts.push(new SurveyPromptDto());
		updateState();
	};

	const removePrompt = () => {
		if (selectedIndex) {
			current.prompts.splice(selectedIndex, 1);
			setSelectedIndex(null);
			updateState();
		}
	};

	const selectPrompt = (selectedIndex) => {
		setSelectedIndex(selectedIndex);
	};

	const addPromptAnswer = () => {
		selectedPrompt.answers.push({
			text: "",
			imageUrl: "",
		});
		updateState();
	};

	const removeAnswer = (index) => {
		selectedPrompt.answers.splice(index, 1);
		updateState();
	};

	const togglePreview = () => {
		setIsPreviewing(!isPreviewing);
	};

	return (
		<Container maxWidth="lg">
			<Paper>
				<h1>Survey Editor</h1>
				<div>
					<FormControl fullWidth={true}>
						<FormLabel>Title / Name</FormLabel>
						<Input
							value={current.title || ""}
							onChange={(e) => {
								current.title = e.target.value;
								updateState();
							}}
						/>
					</FormControl>
					<FormControl fullWidth={true}>
						<FormLabel>Description</FormLabel>
						<Input
							value={current.description || ""}
							multiline={true}
							inputProps={{ maxLength: 1000 }}
							onChange={(e) => {
								current.description = e.target.value;
								updateState();
							}}
						/>
					</FormControl>

					<TextField
						id="date"
						label="Open Datetime"
						type="datetime-local"
						value={toMMDDYYYYWithHourMintues(current.openTime)}
						onChange={(e) => {
							console.log(e.target.value);
							current.openTime = new Date(e.target.value);
							updateState();
						}}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<br />

					<TextField
						id="date"
						label="Close Datetime"
						type="datetime-local"
						value={toMMDDYYYYWithHourMintues(current.closeTime)}
						onChange={(e) => {
							current.closeTime = new Date(e.target.value);
							updateState();
						}}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<br />
				</div>

				<div className={classNames.buttonRow}>
					<Button color="primary" onClick={save}>
						SAVE
					</Button>
					<Button color="primary" onClick={saveAndClose}>
						SAVE AND CLOSE
					</Button>
					<Button color="secondary" onClick={close}>
						CLOSE
					</Button>
				</div>
			</Paper>
			<Paper className={classNames.paper}>
				<h1>Prompt List Editor</h1>
				<table width="100%">
					<thead>
						<tr className={classNames.promptHeader}>
							<th width="75%">Prompt</th>
							<th width="20%"># of Answers</th>
						</tr>
					</thead>
					<tbody
						onDragEnter={(e) => {
							e.preventDefault();
						}}
						onDragOver={(e) => {
							e.preventDefault();
						}}
					>
						{current.prompts?.map((value, i) => (
							<tr
								className={`${classNames.promptRow} ${
									i === selectedIndex ? classNames.selectedPrompt : ""
								}`}
								onClick={() => selectPrompt(i)}
								draggable={true}
								key={i}
								onDragStart={(e) => onDrag(e, i)}
								onDrop={(e) => onDrop(e, i)}
							>
								<td>{value.text}</td>
								<td>{value.answers.length}</td>
							</tr>
						))}
					</tbody>
				</table>
				<br />
				<div className={classNames.buttonRow}>
					<Button onClick={addPrompt}>
						<AddCircle color="primary" /> Add Prompt
					</Button>
					<Button onClick={removePrompt}>
						<RemoveCircle color="primary" /> Remove Prompt
					</Button>
				</div>
			</Paper>

			{selectedPrompt && (
				<Paper className={classNames.paper}>
					<h1>Prompt Answer Editor</h1>

					<FormControl fullWidth={true}>
						<FormLabel>Question</FormLabel>
						<Input
							value={selectedPrompt.text}
							onChange={(e) => {
								selectedPrompt.text = e.target.value;
								updateState();
							}}
						/>
					</FormControl>
					<Button
						onClick={togglePreview}
						color={isPreviewing ? "secondary" : "primary"}
					>
						{isPreviewing ? "Stop Previewing" : "Preview "} Answers
					</Button>
					<TileGroup
						items={selectedPrompt.answers}
						itemName="Answer"
						component={
							/**
							 *
							 * @param {SurveyPromptAnswerDto} item
							 * @param {number} index
							 * @returns
							 */
							(item, index) => {
								if (isPreviewing) {
									return <SurveyAnswerTile answer={item} />;
								} else {
									return (
										<Box style={{ textAlign: "center" }}>
											<FormControl fullWidth={true}>
												<FormLabel>Text</FormLabel>
												<Input
													value={item.text}
													onChange={(e) => {
														item.text = e.target.value;
														updateState();
													}}
												/>
											</FormControl>
											<FormControl fullWidth={true}>
												<FormLabel>Image URL</FormLabel>
												<Input
													value={item.imageUrl}
													onChange={(e) => {
														item.imageUrl = e.target.value;
														updateState();
													}}
												/>
											</FormControl>
											<Button
												style={{ marginTop: 20 }}
												onClick={() => {
													removeAnswer(index);
												}}
											>
												<RemoveCircle color="primary" /> Remove Answer
											</Button>
										</Box>
									);
								}
							}
						}
						showAddTile={!isPreviewing}
						paperProps={{
							padding: isPreviewing ? 0 : 8,
							margin: 0,
						}}
						tileWidth={200}
						tileHeight={224}
						maxItemsPerRow={2}
						onAdd={addPromptAnswer.bind(this)}
					/>

					<div className={classNames.buttonRow}>
						<Button color="primary" onClick={save}>
							SAVE
						</Button>
						<Button color="primary" onClick={saveAndClose}>
							SAVE AND CLOSE
						</Button>
						<Button color="secondary" onClick={close}>
							CLOSE
						</Button>
					</div>
				</Paper>
			)}
		</Container>
	);
}

function SurveyEditor(props) {
	return connect(<SurveyEditorC />, propKeys, props);
}

export default SurveyEditor;
