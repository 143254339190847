import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from "@material-ui/core";
import LoadingComponent from "common/components/Loader/Loader";
import ModalHelper from "common/helpers/modalHelper";
import SettingsHelper from "common/helpers/settingsHelper";
import { changeUrl } from "common/history";
import Layout from "components/Layout";
import AdminScreen from "components/screens/Admin/AdminScreen";
import EventAnalytics from "components/screens/Admin/EventAnalytics/EventAnalytics";
import EventCreator from "components/screens/Admin/EventCreator/EventCreator";
import EventFormatBuilder from "components/screens/Admin/EventFormatBuilder/EventFormatBuilder";
import UserManagerScreen from "components/screens/Admin/UserManager/UserManager";
import NameplateEditor from "components/screens/Admin/NameplateEditor/NameplateEditor";
import PickData from "components/screens/Admin/PickData/PickData";
import { PickUsernameScreen } from "components/screens/Authorization/PickUsername/PickUsernameScreen";
import ResetPasswordScreen from "components/screens/Authorization/ResetPassword/ResetPasswordScreen";
import SignInDiscord from "components/screens/Authorization/SignIn/SignInDiscord";
import SignInGoogle from "components/screens/Authorization/SignIn/SignInGoogle";
import LeagueScreen from "components/screens/Leagues/Leagues";
import RocketLeagueScreen from "components/screens/RocketLeague";
import TrophyRoomScreen from "components/screens/TrophyRoom/TrophyRoom";
import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Colors } from "styles/Colors";
import "./App.scss";
import NameplateService from "services/NameplateService";
import UserManager from "common/userManager";
import RedeemPromoCodeScreen from "components/screens/RedeemPromoCodeScreen";
import GiveTrophy from "components/screens/Admin/GiveTrophy/GiveTrophy";
import LandingScreen from "components/screens/LandingScreen";
import {
	games,
	GetCurrentGameId,
	GetCurrentGamePrefix,
	SetGameInStore,
} from "common/constants/Games";
import AdminPickOverride from "components/screens/Admin/AdminPickOverride/AdminPickOverride";
import SignInReddit from "components/screens/Authorization/SignIn/SignInReddit";
import LinkDiscord from "components/screens/Authorization/Link/LinkDiscord";
import LinkGoogle from "components/screens/Authorization/Link/LinkGoogle";
import LinkReddit from "components/screens/Authorization/Link/LinkReddit";
import SurveyBuilder from "components/screens/Admin/SurveyBuilder/SurveyBuilder";
import SurveyService from "services/SurveyService";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import UserSurveyScreen from "components/screens/Survey/UserSurveyScreen";
import AboutUsScreen from "components/screens/AboutUsScreen";

export default function App() {
	useEffect(() => {
		Promise.all([
			SettingsHelper.loadSettingsFromApi(),
			NameplateService.init(),
			UserManager.getUserData(),
			new Promise((resolve) => {
				SurveyService.getCurrentSurvey().then((currentSurvey) => {
					Store.set(StoreKeys.SURVEY.CURRENT, currentSurvey);
					resolve();
				});
			}),
		]);
	});

	const theme = responsiveFontSizes(
		createTheme({
			palette: {
				primary: {
					main: Colors.primary,
				},
				secondary: {
					main: Colors.pickstop,
				},
				text: {
					primary: Colors.primary,
					secondary: Colors.pickstop,
				},
			},
			spacing: 4,
			shape: {
				borderRadius: 12,
			},
			breakpoints: {
				values: {
					xs: 0,
					sm: 600,
					md: 800,
					lg: 1050,
					xl: 1920,
				},
			},
			overrides: {
				MuiInputBase: {
					input: {
						"&:-webkit-autofill": {
							transitionDelay: "9999s",
							transitionProperty: "background-color, color",
						},
					},
				},
				MuiTableCell: {
					root: {
						borderColor: `${Colors.body} !important`,
						padding: 4,
					},
					head: {
						color: Colors.pickstop,
					},
					body: {
						borderBottom: 0,
					},
					stickyHeader: {
						backgroundColor: Colors.panel,
					},
				},
				MuiDivider: {
					root: {
						backgroundColor: `${Colors.body} !important`,
						width: "100%",
						marginTop: 16,
						marginBottom: 16,
					},
				},
			},
		})
	);

	let showMenuTabs = true;
	showMenuTabs =
		!useRouteMatch({
			path: "/",
			exact: true,
		}) && showMenuTabs;
	showMenuTabs =
		!useRouteMatch({
			path: "/about-us",
			exact: true,
		}) && showMenuTabs;

	const gameRoutes = games.map((game, i) => (
		<Route path={`/${game.prefix}`} key={i}>
			<RelativeRoutes />
		</Route>
	));

	return (
		<ThemeProvider theme={theme}>
			<LoadingComponent />
			<ModalHelper />
			<Layout showMenuTabs={showMenuTabs}>
				<Switch>
					{gameRoutes}
					<Route exact path="/" component={LandingScreen} />
					<Route exact path="/about-us" component={AboutUsScreen} />
					<Route path="/redeem" component={RedeemPromoCodeScreen} />

					{/** Auth Routes */}
					<Route exact path="/signin-discord" component={SignInDiscord} />
					<Route exact path="/signin-google" component={SignInGoogle} />
					<Route exact path="/signin-reddit" component={SignInReddit} />
					<Route exact path="/link-discord" component={LinkDiscord} />
					<Route exact path="/link-google" component={LinkGoogle} />
					<Route exact path="/link-reddit" component={LinkReddit} />
					<Route exact path="/pick-username" component={PickUsernameScreen} />
					<Route exact path="/reset-password" component={ResetPasswordScreen} />

					{/** Admin Routes */}
					<Route exact path="/admin" component={AdminScreen} />
					<Route exact path="/admin/survey" component={SurveyBuilder} />
					<Route exact path="/admin/user" component={UserManagerScreen} />
					<Route exact path="/admin/nameplate" component={NameplateEditor} />
					<Route exact path="/admin/event/create" component={EventCreator} />
					<Route
						exact
						path="/admin/eventFormatBuilder"
						component={EventFormatBuilder}
					/>
					<Route
						exact
						path="/admin/event/analytics"
						component={EventAnalytics}
					/>
					<Route
						exact
						path="/admin/pickOverride"
						component={AdminPickOverride}
					/>
					<Route exact path="/admin/pickdata" component={PickData} />
					<Route exact path="/admin/giveTrophy" component={GiveTrophy} />
					<Route
						render={() => {
							const route = GetCurrentGamePrefix();
							changeUrl(route);
							return <></>;
						}}
					/>
				</Switch>
			</Layout>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				transition={Slide}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</ThemeProvider>
	);
}

function RelativeRoutes() {
	let { path } = useRouteMatch();
	const currentGameId = GetCurrentGameId();
	const pathGameId = games.find((g) => path === `/${g.prefix}`)?.id;
	if (currentGameId !== pathGameId) {
		SetGameInStore(pathGameId);
	}

	return (
		<Switch>
			<Route
				exact
				path={`${path}/event/:eventId?/:userId?`}
				component={RocketLeagueScreen}
			/>
			<Route exact path={`${path}/leagues`} component={LeagueScreen} />
			<Route exact path={`${path}/trophy-room`} component={TrophyRoomScreen} />
			{path === "/rl" && (
				<Route
					exact
					path={`${path}/rl-pro-awards`}
					component={UserSurveyScreen}
				/>
			)}
			{/* <Route exact path={`${path}/community`} component={Community} /> */}
			<Route component={RocketLeagueScreen} />
		</Switch>
	);
}
