import * as React from "react";
import { Grid } from "@mui/material";
import Paper, { PaperComponentProps } from "../Paper/Paper";
import { Box } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

/**
 * @typedef TileGroupProps
 * @type {object}
 * @property {any[]} items
 * @property {(o: any) => JSX.Element} component
 * @property {any} tileProps
 * @property {PaperComponentProps} paperProps
 * @property {boolean} showAddTile
 * @property {number} tileWidth
 * @property {number} tileHeight
 * @property {string} itemName
 * @property {() => void} onAdd
 */

/**
 *
 * @param {TileGroupProps} props
 * @returns
 */
export default function TileGroup({
	items,
	tileProps,
	showAddTile,
	tileWidth,
	tileHeight,
	itemName,
	paperProps,
	component,
	onAdd,
	disableSelect,
	onSelect,
	selectedId,
	getItemId,
	selectedClassName,
}) {
	return (
		<Grid container spacing={2}>
			{items.map((item, index) => {
				let selected = false;
				if (getItemId && selectedId) {
					selected = getItemId(item) === selectedId;
				}

				return (
					<Grid
						item
						xs={6}
						{...tileProps}
						justifyContent={index % 2 === 0 ? "flex-end" : "flex-start"}
						display="flex"
					>
						<Paper
							{...paperProps}
							width={tileWidth}
							height={tileHeight}
							onClick={() => {
								if (disableSelect || !onSelect) return;

								onSelect(item);
							}}
							rootClassName={selected ? selectedClassName : ""}
						>
							<Box>{component(item, index)}</Box>
						</Paper>
					</Grid>
				);
			})}
			{showAddTile && (
				<Grid
					item
					xs={6}
					{...tileProps}
					justifyContent={items.length % 2 === 0 ? "flex-end" : "flex-start"}
					display="flex"
				>
					<Paper {...paperProps} width={tileWidth} height={tileHeight}>
						<Box
							onClick={onAdd}
							style={{ cursor: "pointer", height: "100%" }}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<AddCircle /> Add {itemName}
						</Box>
					</Paper>
				</Grid>
			)}
		</Grid>
	);
}
