export const Routes = {
	COMMUNITY: "/community",
	LEAGUES: "/leagues",
	PICKEMS: "/pickems",
	RLAWARDS: "/rl-pro-awards",
	TROPHYROOM: "/trophy-room",
};

export const Tabs = {
	COMMUNITY: "community",
	LEAGUES: "leagues",
	PICKEMS: "pickems",
	RLAWARDS: "rl-pro-awards",
	TROPHYROOM: "trophy-room",
};
