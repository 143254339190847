import * as React from "react";
import { Button, makeStyles } from "@material-ui/core";
import StoreKeys from "common/storeKeys";
import { connect } from "common/connect";
import SurveyAnswerTile from "common/components/SurveyAnswerTile/SurveyAnswerTile";
import TileGroup from "common/components/TileGroup/TileGroup";
import { SurveyPromptDto } from "common/types/Survey/SurveyPromptDto";
import { SurveyDto } from "common/types/Survey/SurveyDto";
import { Colors } from "styles/Colors";
import NameplateButton from "common/components/NameplateButton";
import { SurveyPromptAnswerDto } from "common/types/Survey/SurveyPromptAnswerDto";
import SurveyService from "services/SurveyService";
import { SetLoading } from "common/components/Loader/Loader";
import UserManager from "common/userManager";
import ModalHelper from "common/helpers/modalHelper";
import AccountRequired from "common/components/Modals/AccountRequired/AccountRequired";
import SurveyCompleteModal from "./SurveyCompleteModal";

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {SurveyDto} currentSurvey
 * @property {number[]} currentPicks
 */
const propKeys = {
	currentSurvey: StoreKeys.SURVEY.CURRENT,
	currentPicks: StoreKeys.SURVEY.PICKS,
};

const useStyles = makeStyles((theme) => {
	return {
		buttonRow: {
			marginTop: theme.spacing() * 2,
			marginBottom: 12,
			"& svg": {
				position: "relative",
				top: -2,
				left: -2,
			},
		},
		promptHeader: {
			textAlign: "center",
			marginBottom: 16,
			backgroundColor: Colors.primary,
			color: Colors.panelDark,
			fontWeight: 600,
			fontSize: 16,
			width: 400,
			borderRadius: 12,
			padding: 2,
		},
		selectedAnswer: {
			boxShadow: `0px 2px 20px ${Colors.green}`,
			backgroundColor: Colors.greenPanel,
		},
	};
});

/**
 * @param {ComponentProps} props
 */
function UserSurveyScreenC({ currentSurvey, currentPicks }) {
	const classNames = useStyles();
	const [answers, setAnswers] = React.useState({});
	const [promptIndex, setPromptIndex] = React.useState(0);

	React.useEffect(() => {
		if (currentSurvey === undefined) {
			SetLoading(true);
		} else {
			if (currentPicks === undefined) {
				if (!UserManager.isLoggedIn()) {
					SetLoading(false);
				}
				else {
					SurveyService.getAnswers(currentSurvey.surveyId).then((answers) => {
						const mappedAnswers = {};
						for (let i = 0; i < currentSurvey.prompts.length; i++) {
							const prompt = currentSurvey.prompts[i];
							mappedAnswers[prompt.promptId] = answers[i];
						}

						setAnswers({ ...mappedAnswers });
						SetLoading(false);
					});
				}
			}
		}
	}, [currentSurvey, currentPicks]);

	if (!currentSurvey) {
		return (
			<>
				We are not currently gathering info here. Come back soon for future
				surveys!
			</>
		);
	}

	const onNext = () => {
		setPromptIndex(promptIndex + 1);
	};

	const onBack = () => {
		setPromptIndex(promptIndex - 1);
	};

	const onSubmit = async () => {
		SetLoading(true, "Saving Answers");
		const answerIds = [];
		for (const key in answers) {
			answerIds.push(answers[key]);
		}

		await SurveyService.saveAnswers(answerIds);
		ModalHelper.openModal(<SurveyCompleteModal survey={currentSurvey} />);
		SetLoading(false);
	};

	/** @type {SurveyPromptDto} */
	const currentPrompt = currentSurvey.prompts[promptIndex];

	/**
	 *
	 * @param {SurveyPromptAnswerDto} item
	 */
	const onAnswerSelect = (item) => {
		if (!UserManager.isLoggedIn()) {
			ModalHelper.openModal(<AccountRequired />);
			return;
		}

		answers[currentPrompt.promptId] = item.promptAnswerId;
		setAnswers({ ...answers });
	};

	const isSubmit = promptIndex === currentSurvey.prompts.length - 1;
	const surveyClosed = new Date(currentSurvey.closeTime) < new Date();

	// Disable if answer not selected for this prompt.
	const nextDisabled = !answers[currentPrompt.promptId];

	return (
		<div
			display="flex"
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				marginTop: 16,
			}}
		>
			<div>
				<div className={classNames.promptHeader}>{currentPrompt.text}</div>
			</div>
			<div>
				<TileGroup
					items={currentPrompt.answers}
					itemName="Answer"
					component={
						/**
						 *
						 * @param {SurveyPromptAnswerDto} item
						 * @param {number} index
						 * @returns
						 */
						(item, index) => {
							return (
								<SurveyAnswerTile
									answer={item}
									selected={
										answers[currentPrompt.promptId] === item.promptAnswerId
									}
								/>
							);
						}
					}
					paperProps={{
						padding: 0,
						margin: 0,
					}}
					tileWidth={200}
					tileHeight={224}
					maxItemsPerRow={2}
					disableSelect={surveyClosed}
					onSelect={onAnswerSelect}
					selectedId={answers[currentPrompt.promptId]}
					getItemId={(item) => {
						return item.promptAnswerId;
					}}
					selectedClassName={classNames.selectedAnswer}
				/>
			</div>

			<div className={classNames.buttonRow}>
				<NameplateButton
					onClick={onBack}
					buttonText={"BACK"}
					disabled={promptIndex === 0}
				/>
				<NameplateButton
					onClick={() => {
						isSubmit ? onSubmit() : onNext();
					}}
					disabled={nextDisabled}
					buttonText={isSubmit ? "SUBMIT" : "NEXT"}
					nameplateId={isSubmit ? 32 : null}
				/>
			</div>
		</div>
	);
}

export default function UserSurveyScreen(props) {
	return connect(<UserSurveyScreenC />, propKeys, props);
}
