import * as React from "react";
import { SetLoading } from "common/components/Loader/Loader";
import SuccessFailModal from "common/components/Modals/SuccessFailModal/SuccessFailModal";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import UserManager from "common/userManager";
import EventService from "services/EventService";
import ModalHelper from "../modalHelper";
import AccountRequired from "common/components/Modals/AccountRequired/AccountRequired";
import GenericEvent from "./events/GenericEvent";
import { PanZoomHelper } from "../panZoomHelper";

export default class EventHelper {
	/** @type {object} */
	static liveEvent;

	/** @type {number} */
	static liveEventId;

	/** @type {number} */
	static currentEventId;

	/** @type {number} */
	static currentUserId;

	/** @type {Array<number>} */
	static pastEventIds;

	/** @type {Map<number, any>} */
	static events = new Map();

	/** @type {Record<number, Record<number, any>>} */
	static cache = {};

	static getCurrentEventId() {
		return EventHelper.currentEventId;
	}

	static async getLiveEventId() {
		if (EventHelper.liveEventId) {
			return EventHelper.liveEventId;
		}

		let eventId = EventHelper.liveEventId;

		if (!eventId) {
			eventId = await EventService.getNextEventId();
		}
		if (!eventId) {
			eventId = await EventService.getPreviousEventId();
		}
		if (!eventId) {
			return null;
		}

		EventHelper.liveEventId = eventId;
		return eventId;
	}

	/**
	 * Gets the live event or most recent event.
	 * @returns The event
	 */
	static async getLiveEvent() {
		if (EventHelper.liveEvent) {
			return EventHelper.liveEvent;
		}

		SetLoading(true, "Loading Live Event");
		const eventId = await EventHelper.getLiveEventId();
		await this.fullLoadEvent(eventId, undefined);
		SetLoading(false);
	}

	/**
	 * Gets the list of past event ids for the side pane.
	 * @returns {Promise<Array<number>>} The past event ids.
	 */
	static async getListOfPastEventIds() {
		if (EventHelper.pastEventIds) {
			return EventHelper.pastEventIds;
		}

		const pastEventIds = await EventService.getPastEventIds();
		EventHelper.pastEventIds = pastEventIds;
		return pastEventIds;
	}

	/**
	 * Gets past event by index starting from today.
	 *
	 * @param {number} index
	 * @returns The event based on the past event index.
	 */
	static async getPastEvent(index) {
		const eventId = EventHelper.pastEventIds[index];
		if (EventHelper.events.has(eventId)) {
			return EventHelper.events.get(eventId);
		}

		const event = await EventService.getEvent(eventId);
		EventHelper.events.set(eventId, event);
		return event;
	}

	/**
	 * Gets the event id based on past index.
	 *
	 * @param {number} index
	 * @returns {number}
	 */
	static getPastEventId(index) {
		return EventHelper.pastEventIds[index];
	}

	/**
	 * Gets an event by the specified event id.
	 *
	 * @param {number} eventId
	 */
	static async getSpecificEvent(eventId) {
		if (EventHelper.events.has(eventId)) {
			const event = EventHelper.events.get(eventId);
			Store.set(StoreKeys.EVENT.EVENTDATA, event);
			return event;
		}

		const event = await EventService.getEvent(eventId);
		if (!event) {
			return event;
		}

		EventHelper.events.set(eventId, event);
		Store.set(StoreKeys.EVENT.EVENTDATA, event);
		return event;
	}

	/**
	 * Gets the current event for each active game.
	 * @returns One EventDto for each active game.
	 */
	static async getCurrentEvents() {
		return EventService.getCurrentEvents();
	}

	/**
	 * Fully loads an event into store.
	 *
	 * @param {any} eventId The event id.
	 * @param {number} userId The user id.
	 */
	static async fullLoadEvent(eventId, userId) {
		Store.cacheChanges("EventHelper.fullLoadEvent");
		if (
			userId &&
			UserManager.isLoggedIn() &&
			userId.toString() === UserManager.getUserId().toString()
		) {
			userId = undefined;
		}

		Store.set(StoreKeys.EVENT.SHOW_OFFICIAL, false);

		// If the current user is not viewing the current user's picks. Disable edit mode.
		if (userId?.toString() !== UserManager.getUserId()?.toString()) {
			Store.set(StoreKeys.EVENT.IS_EDITING, false);
		}

		if (
			EventHelper.currentEventId === eventId &&
			EventHelper.currentUserId === userId
		) {
			Store.applyChanges("EventHelper.fullLoadEvent");
			return;
		}

		if (EventHelper.currentEventId !== eventId) {
			PanZoomHelper.resetView(0, 0, 0.8);
		}

		EventHelper.currentEventId = eventId;
		EventHelper.currentUserId = userId;

		const event = await this.getSpecificEvent(eventId);
		if (!event) {
			Store.applyChanges("EventHelper.fullLoadEvent");
			return;
		}

		Store.set(StoreKeys.EVENT.EVENTDATA, event);
		Store.set(
			StoreKeys.EVENT.FORMAT,
			event.format?.json ? JSON.parse(event.format?.json) : null
		);

		const eventData = new GenericEvent(event);
		await eventData.fullLoad(userId);

		if (EventHelper.liveEventId === eventId) {
			EventHelper.liveEvent = event;
		}
		Store.applyChanges("EventHelper.fullLoadEvent");
	}

	static mapStoreKeysToObject(keyObject, targetObject) {
		for (let key in keyObject) {
			const value = keyObject[key];
			if (typeof value === "object") {
				EventHelper.mapStoreKeysToObject(value, targetObject);
			} else {
				// is string
				targetObject[value] = Store.get(value);
			}
		}
	}

	static async submitPicks(currentGame) {
		if (!UserManager.isLoggedIn()) {
			ModalHelper.openModal(<AccountRequired />);
			return;
		}

		SetLoading(true, "Saving Picks");
		if (!EventHelper.liveEvent) {
			SetLoading(false);
			return;
		}

		const event = new GenericEvent(EventHelper.liveEvent);
		const currentStageIndex = Store.get(StoreKeys.EVENT.STAGE_INDEX);
		const format = Store.get(StoreKeys.EVENT.FORMAT);
		const hasNextStage = currentStageIndex < format.stages.length - 1;
		await event
			.submitPicks()
			.then(() => {
				SetLoading(false);
				ModalHelper.openModal(
					<SuccessFailModal
						success={true}
						hasNextStage={hasNextStage}
						currentGame={currentGame}
					/>,
					null
				);
			})
			.catch((s) => {
				SetLoading(false);
				ModalHelper.openModal(<SuccessFailModal success={false} />, null);
			});
	}
}
